<!--  -->
<template>
  <div class="content">
    <div class="inner">
      <div class="money">
        应付金额：
        <span>299元</span>
      </div>
      <p class="tips">支付即同意《API服务协议》</p>
      <div class="ewmBox layout-flex-all">
        <div class="payEle">
          <img src="@/assets/img/ewm.png" alt />
          <p>
            <img src="@/assets/img/pay_wx.jpg" alt />
            使用微信扫码支付
          </p>
        </div>
        <div class="payEle">
          <img src="@/assets/img/ewm.png" alt />
          <p>
            <img src="@/assets/img/pay_zfb.jpg" alt />使用支付宝扫码支付
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    };
  },

  components: {},

  computed: {},

  mounted: {},

  methods: {}
}

</script>
<style lang='scss' scoped>
.content {
  background: $bg;
  min-height: 600px;
  padding: 50px 0;
  .inner {
    padding: 85px 0;
    margin: 0 auto;
    max-width: $minWidth;
    // height: 90%;
    background: #fff;
    border-radius: 10px;
    text-align: center;
    .money {
      margin-bottom: 5px;
      font-size: 18px;
      color: #666666;
      span {
        font-size: 26px;
        color: #ff470a;
      }
    }
    .tips {
      margin-bottom: 40px;
      color: #999;
      font-size: 14px;
    }
    .ewmBox {
      max-width: 580px;
      margin: 0 auto;
      .payEle {
        margin-bottom: 20px;
        & > img {
          width: 164px;
          height: 164px;
          margin: 0 40px;
          padding: 20px;
          border: 1px solid #eee;
        }
        p {
          margin-top: 10px;
          color: #999;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            margin-right: 6px;
            width: 26px;
            height: 26px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 996px) {
  .content {
    padding: 0;
    .inner {
      .ewmBox {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }
}
</style>